<template>
  <div class="assessment">
    <navbar title="房产评估" />
    <div class="assessment-box" v-if="!buildingDetail">
      <div class="search-box">
        <van-search v-model="value" left-icon="" show-action placeholder="请输入小区或地址" @search="onSearch">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="result-box">
        <div class="result-item" v-for="item in resultList" :key="item.Id" @click="showPanel(item.Id,item.Address,item.AreaCode)">
          <div class="item-label">住宅</div>
          <div class="item-name">{{item.Address}}</div>
        </div>
      </div>
      <van-popup v-model="show" :overlay="false" position="right" :style="{ height: '100%',width:'50%' }">
        <div class="building-title">选择楼栋</div>
        <div class="building-box">
          <div class="building-item" @click="showDetail(item)" v-for="(item,index) in buildingList" :key="index">{{item.Name}}</div>
        </div>
      </van-popup>
    </div>
    <div class="building-detail" v-else>

      <div class="show-result" v-if="showResult">
        <div class="result-name">市场评估总价（万元）</div>
        <div class="result-price">{{resultPrice}}</div>
        <div class="result-content">
          <div class="result-msg">
            <div class="result-text">面积（㎡）</div>
            <div class="result-num">{{resultArea}}</div>
          </div>
          <div class="result-msg">
            <div class="result-text">单价（元/㎡）</div>
            <div class="result-num">{{unitPrice}}</div>
          </div>
        </div>
        <div class="result-btn" @click="showTips">
          <van-icon name="info" />
        </div>
      </div>

      <div class="detail-box">
        <div class="detail-title">{{detailMsg.title}}</div>
        <div class="detail-from">
          <div class="from-item">
            <van-field v-model="detailMsg.address" input-align="right" readonly label="详细地址" required>
            </van-field>
          </div>
          <div class="from-item" @click="showResult ? '' : openPopup('building')">
            <van-field v-model="detailMsg.building" label="选择楼栋" required :right-icon="showResult ? '' : 'arrow'" input-align="right" placeholder="请选择楼栋" readonly />
          </div>
          <div class="from-item">
            <van-field v-model="detailMsg.number" input-align="right" :readonly="showResult ? true : false" label="室号" placeholder="请填写室号">
            </van-field>
          </div>
          <div class="from-item" @click="showResult ? '' : openPopup('type')">
            <van-field v-model="detailMsg.type" label="物业类型" :right-icon="showResult ? '' : 'arrow'" input-align="right" placeholder="请选择物业类型" readonly />
          </div>
          <div class="from-item">
            <van-field v-model="detailMsg.landFloor" type="number" :readonly="showResult ? true : false" input-align="right" label="地上楼层" placeholder="请填写地上楼层" @input="landFloorInput" required>
            </van-field>
          </div>
          <div class="from-item" @click="showResult ? '' : openPopup('activeFloor')">
            <van-field v-model="detailMsg.activeFloor" label="所在楼层" :right-icon="showResult ? '' : 'arrow'" input-align="right" placeholder="请选择所在楼层" readonly required />
          </div>
          <div class="from-item">
            <van-field v-model="detailMsg.area" input-align="right" :readonly="showResult ? true : false" required type="number" label="建筑面积" placeholder="请输入建筑面积">
              <template #extra>
                <div class="input-right">m²</div>
              </template>
            </van-field>
          </div>
          <div class="from-item" @click="showResult ? '' : openPopup('orientation')">
            <van-field v-model="detailMsg.orientation" label="朝向" :right-icon="showResult ? '' : 'arrow'" input-align="right" placeholder="请选择朝向" readonly />
          </div>
          <div class="from-item">
            <van-field v-model="detailMsg.year" input-align="right" :readonly="showResult ? true : false" required type="number" label="竣工年份" placeholder="请输入竣工年份">
              <template #extra>
                <div class="input-right">年</div>
              </template>
            </van-field>
          </div>
        </div>
      </div>
      <div class="detail-btn" @click="goAssessment" v-if="showResult  && status != 0">
        再询一套
      </div>
      <div class="detail-btn" @click="goBack" v-if="showResult  && status == 0">
        返回房源发布
      </div>
      <div class="detail-btn" @click="inquiry" v-else>
        立即询价
      </div>

    </div>

    <!-- 弹窗组件 -->
    <popup :showPopup="showPopup" :columns="columns" @closePopup="closePopup" @checkPopup="getValue"></popup>
  </div>
</template>
<script>
import navbar from "../components/NavBar.vue";
import popup from "../components/Popup.vue";

export default {
  name: "assessment",
  data() {
    return {
      value: "",
      resultList: [],
      buildingList: [],
      show: false,
      buildingDetail: false,
      showPopup: false,
      popupName: "", //传递给子组件选中弹窗的名称
      columns: [], //传递给弹窗子组件的内容
      FloorId:'',
      detailMsg: {
        title: "",
        address: "",
        building: "",
        number: "",
        type: "",
        landFloor: null,
        activeFloor: "",
        orientation: "",
        area: null,
        year: null,
      },
      popupConList: {
        //所有弹窗内容对象
        activeFloor: [],
        building: [
          // {values: ["1号楼", "2号楼", "3号楼", "4号楼", "5号楼"]},
        ],
        type: ["普通住宅", "公寓", "老公房", "别墅", "商铺", "其他"],
        orientation: [
          "正东",
          "正西",
          "正南",
          "正北",
          "东南",
          "西南",
          "东北",
          "西北",
          "东西",
          "南北",
        ],
      },
      resultPrice: "",
      resultArea: "",
      unitPrice: "",
      showResult: false,
      status: null,
      communityid:'',
      AreaCode:'',
    };
  },
  created() {
    this.status = this.$route.query.status;
    this.value = this.$route.query.name;
    this.floor = this.$route.query.floor;
    this.type = this.$route.query.type;
    this.area = this.$route.query.area;
    this.orientation = this.$route.query.orientation;
    this.years = this.$route.query.years;
    //
    //floor:楼层 , type:物业类型 , area:面积 , orientation:朝向 , years:年代
    this.detailMsg.activeFloor = this.floor;
    this.detailMsg.type = this.type;
    this.detailMsg.orientation = this.orientation;
    this.detailMsg.area = this.area;
    this.detailMsg.year = this.years;
    if (this.detailMsg.activeFloor == undefined) {
      this.detailMsg.activeFloor = "";
    }
    if (this.detailMsg.type == undefined) {
      this.detailMsg.type = "";
    }
    if (this.detailMsg.orientation == undefined) {
      this.detailMsg.orientation = "";
    }
    if (this.detailMsg.area == undefined) {
      this.detailMsg.area = "";
    }
    if (this.detailMsg.year == undefined) {
      this.detailMsg.year = "";
    }
    // this.value = this.$route.query.address;
    this.onSearch();
  },
  methods: {
    // 再询一套
    goAssessment() {
      this.$router.push("/my/houseAssessment");
    },
    goBack() {
      this.$router.push({
        path: "/release",
        query:{
          resultPrice : this.resultPrice
        }
      });
    },
    // 询价
    inquiry() {
      if (!this.detailMsg.activeFloor){
        this.$toast.fail("请输入所在楼层");
        return false;
      }
      if (!this.detailMsg.area) {
        this.$toast.fail("请输入建筑面积");
        return false;
      }
      this.resultArea = this.detailMsg.area
      const data = {
        ProjectId:this.communityid,
        BuildArea:this.detailMsg.area,
        FloorId:this.FloorId,
        CurrentFloor:this.detailMsg.activeFloor,
        CompletionYear:this.detailMsg.year,
        Toward:this.detailMsg.orientation,
        TotalFloor:this.detailMsg.landFloor,
        Address:this.detailMsg.building,
        AreaCode:this.AreaCode
      }
      this.$post("secondHouse/getPrice",data).then((res)=>{
        if (res.status==200){
          this.resultPrice= Math.floor((res.data.TotalPrice /10000) * 100) / 100; //保留小数点两位
         this.unitPrice= res.data.UnitPrice
        }
      })
      this.showResult = true;
    },
    onSearch() {
      const data = {
        address: this.value,
      };
      this.$post("secondHouse/getCommunity", data).then((res) => {
        if (res.status == 200) {
          this.resultList = res.data.list;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
    showPanel(id, Address,AreaCode) {
      this.AreaCode = AreaCode;
      this.communityid = id;
      this.show = true;
      const data = {
        id: id,
      };
      this.$post("secondHouse/getBuilding", data).then((res) => {
        if (res.status == 200) {
          this.buildingList = res.data.list;
          this.detailMsg.title = Address;
          this.detailMsg.address = Address;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },



    //关闭弹窗
    closePopup() {
      this.showPopup = false;
    },
    // 获取弹窗的值
    getValue(checkName) {
      console.log("获取的数据" + checkName);
      this.detailMsg[this.popupName] = checkName;
      this.showPopup = false;
    },
    openPopup(value) {
      console.log("获取我的value" + value);
      if (value == "building") {
        this.popupConList.building = [];
        for (var i = 0; i < this.buildingList.length; i++) {
          this.popupConList.building.push(this.buildingList[i].Name);
        }
      }
      this.columns = this.popupConList[value];
      this.popupName = value;
      this.showPopup = true;
    },
    showDetail(item) {
      // this.detailMsg.address = this.value
      this.detailMsg.building = item.Name;
      this.FloorId = item.Id
      this.detailMsg.landFloor = item.LandLayerCount;
      // this.FloorId =

      for (let i = 1; i <= this.detailMsg.landFloor; i++) {
        this.popupConList.activeFloor.push(i);
      }

      this.detailMsg.year = item.CompletionYear;
      this.buildingDetail = true;
    },
    showTips() {
      this.$dialog
        .alert({
          title: "提示",
          message: "市场评估价值：正常市场条件下的客观市场价值",
        })
        .then(() => {
          // on close
        });
    },
    // 获取地上楼层的值
    landFloorInput(value) {
      this.popupConList.activeFloor = [];
      for (let i = 1; i <= value; i++) {
        this.popupConList.activeFloor.push(i);
      }
    },
  },
  components: {
    navbar,
    popup,
  },
};
</script>
<style lang="less" scoped>
.assessment {
  .search-box {
    padding: 15px 15px 20px;
    /deep/.van-search {
      border-radius: 5px;
      box-shadow: 0 20px 21px rgba(204, 204, 204, 0.25);
      .van-search__content {
        background-color: #fff;
        .van-field__control {
          font-size: 16px;
          &::placeholder {
            color: #858585;
          }
        }
      }
      .van-search__action {
        padding: 0 20px;
        color: #31c8b6;
        font-size: 16px;
        border-left: 1px solid #f1f1f1;
        &:active {
          background-color: #fff;
        }
      }
    }
  }

  .result-box {
    padding: 0 15px;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    .result-item {
      width: 100%;
      display: flex;
      align-items: center;
      height: 60px;
      border-bottom: 1px solid #eee;
      min-width: 0;
      &:last-child {
        border-bottom: 0;
      }
      .item-label {
        padding: 0 15px;
        height: 24px;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        line-height: 24px;
        margin-right: 15px;
        flex-shrink: 0;
        background-color: #31c8b6;
      }
      .item-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        flex-direction: column;
        color: #000;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  /deep/.van-popup {
    color: #000;
    font-size: 15px;
    box-shadow: -3px 0px 11px rgba(71, 64, 64, 0.1);
    .building-title {
      height: 46px;
      border-bottom: 1px solid #ddd;
      line-height: 46px;
      padding: 0 15px;
      box-sizing: border-box;
    }
    .building-box {
      padding: 0 15px;
      .building-item {
        line-height: 24px;
        padding: 10px 0;
        display: flex;
        align-items: center;
      }
    }
  }
  .building-detail {
    padding: 15px;

    .show-result {
      color: #fff;
      padding: 15px 15px 5px;
      background: linear-gradient(
        to right,
        rgb(49, 200, 182) 35%,
        rgb(48, 225, 204) 100%
      );
      border-radius: 10px;
      margin-bottom: 30px;
      text-align: center;
      position: relative;
      .result-btn {
        position: absolute;
        font-size: 24px;
        top: 15px;
        right: 15px;
        color: #d5f8f4;
        height: 24px;
        width: 24px;
        line-height: 24px;
      }
      .result-name {
        font-size: 14px;
        padding-top: 5px;
      }
      .result-price {
        font-size: 35px;
        font-weight: 700;
        padding: 5px 0;
        border-bottom: 1px solid #fff;
      }
      .result-content {
        padding: 10px 20px 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        .result-msg {
          display: flex;
          flex-direction: column;
          align-items: center;
          .result-text {
            font-size: 14px;
          }
          .result-num {
            font-size: 18px;
          }
        }
      }
    }
    .detail-box {
      background-color: #fff;
      border-radius: 10px;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      .detail-title {
        color: #00d3c4;
        font-size: 17px;
        padding: 10px 0;
      }
      .detail-from {
        background-color: #fff;
        box-sizing: border-box;

        .from-item {
          padding: 13px 8px;
          border-bottom: 1px solid #ddd;
          &:last-child {
            border-bottom: 0;
          }
          /deep/.van-cell {
            padding: 0;
            &::after {
              border-bottom: 0;
            }
            .van-cell__title {
              color: #000;
            }
            .van-field__label {
              padding-left: 5px;
              font-size: 15px;
            }
            &.van-cell--required::before {
              left: -2px;
            }
            input {
              font-size: 15px;
              color: #000;
            }
            input::-webkit-input-placeholder {
              color: #999;
            }
          }
          .input-right {
            margin-left: 10px;
          }
        }
        .from-more {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ff8000;
          font-size: 14px;
          background-color: #f8f8f8;
        }
      }
    }
    .detail-btn {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #00d3c4;
      border-radius: 5px;
      font-size: 18px;
      margin-top: 15px;
    }
  }
}
</style>